<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader v-if="$permission.isStoreOrOffice() || isGmoOrOfficeLoginStore" :pageName="pageName" :pageNavs="pageNavs" />
      <PageHeader v-else-if="$permission.isGmo() || $permission.isGroup()" :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>アカウント情報</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow v-if="$permission.isGroup()" :required="true">
                      <template v-slot:label>グループ名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.nameSei }"
                            class="form-control"
                            type="text"
                            name="nameGroup"
                            v-trim
                            v-maxlength
                            maxlength="20"
                            v-model="form.nameSei"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow v-else :required="true">
                      <template v-slot:label>ユーザー名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameSei }"
                                type="text"
                                name="nameSei"
                                placeholder="姓"
                                v-trim
                                v-maxlength
                                maxlength="100"
                                v-model="form.nameSei"
                              />
                            </div>
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameMei }"
                                type="text"
                                name="nameMei"
                                placeholder="名"
                                v-trim
                                v-maxlength
                                maxlength="100"
                                v-model="form.nameMei"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>メールアドレス / ID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <input
                            :class="{ 'form-control': true, 'is-error': errors.email }"
                            type="text"
                            name="email"
                            v-email
                            v-trim
                            v-maxlength
                            maxlength="50"
                            v-model="form.email"
                          />
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>現在のパスワード</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-4">
                              <div class="form-password">
                                <input
                                  v-trim
                                  v-model="form.password"
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.password }"
                                  :type="pwdVisible ? 'text' : 'password'"
                                  name="password"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="pwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>新しいパスワード</template>
                      <template v-slot:labelNote><span class="text-note">（10文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ）</span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-4">
                              <div class="form-password">
                                <input
                                  v-trim
                                  v-model="form.newPassword"
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.newPassword }"
                                  :type="pwdNewVisible ? 'text' : 'password'"
                                  name="newPassword"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="pwdNewVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>確認用のパスワード</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-4">
                              <div class="form-password">
                                <input
                                  v-trim
                                  v-model="form.confirmPassword"
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.confirmPassword }"
                                  :type="pwdConfirmVisible ? 'text' : 'password'"
                                  name="confirmPassword"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="pwdConfirmVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <UserMailSetting
                      :formData="form"
                      @updateData="updateData"
                      :disabled="isAuthEmailFlagDisabled"
                      :hasReceptionStoreEmailFlag="$permission.isGroup()"
                    />
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section v-if="$permission.isGroup()" class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>グループ内の店舗</template>
              <template v-slot:body>
                <table class="listTable">
                  <tbody>
                    <tr v-for="item in shopList" :key="item.index">
                      <td>{{ item.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ActionButton class="btn btn-main" :handle-submit="edit" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, maxLength, sameAs } from 'vuelidate/lib/validators';
//helpers
import { isAlphanumSpecialWithMinLength } from '@/helpers/validators';
//mixin
import nav from '@/mixins/nav/account';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
//component
import FormRow from '@/components/FormRow.vue';
import UserMailSetting from '@/components/UserMailSetting.vue';

export default {
  name: 'AccountUser',
  data: function() {
    return {
      pageName: 'アカウント設定',
      pwdNewVisible: false,
      pwdConfirmVisible: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      userAccount: 'account/userAccount',
      shopList: 'shop/shopList',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
    }),
    isAuthEmailFlagDisabled() {
      return (this.$permission.isStoreOrGroup() || this.$permission.isOffice()) && !!this.infor?.onAuthEmailByMasterFlag;
    }
  },
  components: {
    FormRow,
    UserMailSetting,
  },
  validations() {
    const form = {
      nameSei: { required, maxLength: maxLength(100) },
      nameMei: {},
      email: { required, email, maxLength: maxLength(50) },
      password: { required },
      newPassword: { alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(10) },
    }
    if (this.form.newPassword) {
      form.confirmPassword = { required }
    }
    if (this.form.confirmPassword) {
      form.newPassword = { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(10) }
      form.confirmPassword = { sameAsNewPassword: sameAs('newPassword') }
    }
    if (!this.$permission.isGroup()) {
      form.nameSei = { ...form.nameSei }
      form.nameMei = { required, maxLength: maxLength(100) }
    }
    return { form };
  },
  mixins: [nav, error, password],
  watch: {
    'form.authEmailFlag' (val) {
      if (this.isAuthEmailFlagDisabled && this.infor?.authEmailFlag && !val) {
        this.$message.showError('notChangeWhenOnAuthEmailFlag');
        this.$nextTick(() => {
          this.form.authEmailFlag = 1;
        });
      }
    }
  },
  methods: {
    async edit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (this.$permission.isGroup()) {
          this.getErrors(this.$v.form, 'inforGroup');
        } else {
          this.getErrors(this.$v.form, 'infor');
        }
      } else {
        const result = await this.$store.dispatch('account/updateUserAccount', this.form);
        if (result) {
          this.resetPasswordForm();
          this.$message.updated('accountInfo');
          await this.$store.dispatch('auth/getUserInfor');
        }
      }
    },
    resetPasswordForm() {
      this.form.password = '';
      this.form.newPassword = '';
      this.form.confirmPassword = '';
    },
    updateData(data) {
      this.form = { ...this.form, ...data };
    },
    async getShopList() {
      if (this.$permission.isGroup()) {
        await this.$store.dispatch('shop/getShopList', {
          limit: 100,
          subdomain: this.infor.event?.subdomain,
        });
      }
    }
  },
  created() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('auth/getUserInfor'),
      this.getShopList(),
    ]).finally(() => {
      this.form = { ...this.infor };
      this.$loading.clear(loading);
    })
  }
};
</script>
